<template>
  <section class="home d-flex align-items-center">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-md-6">
          <div class="home-text">
            <h1>Bronkoscopy</h1>
            <p style="font-weight: bold">Apakah bronkoskopi itu?</p>
            <p>
              Bronkoskopi adalah suatu prosedur yang bertujuan untuk melihat dan
              mengevaluasi secara langsung sistem saluran napas manusia termasuk
              anak. Prosedur ini dilakukan mulai dari saluran napas atas
              (faring, laring, trakea) hingga saluran napas bawah (bronkus dan
              cabang-cabangnya). Alat yang digunakan untuk prosedur ini adalah
              bronkoskop, sebuah alat seperti pipa kecil yang terdiri dari
              banyak serat optik yang berfungsi mirip dengan teropong.
            </p>
            <br />

            <p style="font-weight: bold">
              Apakah tujuan tindakan bronkoskopi dilakukan?
            </p>
            <p>
              Tujuan dari prosedur ini adalah untuk menegakkan ada atau tidaknya
              kelainan (diagnosis) di saluran napas serta melakukan tindakan
              terapi bila dibutuhkan.
            </p>
            <br />
            <p style="font-weight: bold">Bagaimana prosedur ini dilakukan?</p>
            <p>
              Tindakan ini dilakukan di ruangan khusus atau di ruang operasi.
              Sebelum tindakan pasien (anak) dievaluasi kondisi dan toleransi
              terhadap tindakan tersebut. Setelah hasil evaluasi dinyatakan
              dalam kondisi stabil maka prosedur dapat segera dilakukan.
              Kemudian dalam keadaan dibius umum oleh dokter spesialis anestesi
              tindakan dapat dimulai. Serat optik dimasukkan melalui hidung atau
              mulut untuk menuju saluran napas lain, seperti laring, glottis,
              pita suara, trakea, bonkus dan cabang-cabangnya. Kondisi ini dapat
              dilihat di layar monitor di ruang tindakan.
            </p>
            <br />

            <p>
              Selama prosedur kondisi dan tanda vital anak diobservasi secara
              ketat sehingga diharapkan selalu dalam kondisi yang optimal.
              Segera setelah tindakan selesai anak kemudian dipindahkan ke ruang
              pemulihan untuk dimonitor lebih lanjut selama beberapa saat. Pasca
              tindakan bronkoskopi pasien dapat dipindahkan ke ruangan rawat
              biasa atau ICU (bila diperlukan) dengan perhatian khusus.
            </p>
            <br />

            <p style="font-weight: bold">
              Pada kondisi seperti apa prosedur ini perlu dilakukan?
            </p>
            <p>
              Indikasi prosedur bronkoskopi pada anak diantaranya adalah
              bayi/anak dengan suara napas mengi yang selalu ada (persistent
              wheezing), suara napas kasar seperti berlendir/grok-grok yang
              selalu ada (stridor), kecurigaan adanya benda asing di saluran
              napas, batuk berdarah, infeksi paru yang berat, gambaran kelainan
              di foto rontgen paru seperti kecurigaan adanya sumbatan saluran
              napas (atelektasis paru), pengambilan cairan saluran napas bawah
              untuk dibiakkan (dikultur) di laboratorium, dan sebagainya.
            </p>
            <br />

            <p style="font-weight: bold">Apakah tujuan tindakan bronkoskopi?</p>
            <p>
              Secara umum tujuan tindakan ini adalah dua hal, yaitu untuk
              menegakkan diagnosis (kelainan saluran napas) dan untuk terapi
              (misalnya mengeluarkan benda asing, mengisap lendir yang
              mengganggu saluran napas). Tujuan diagnosis adalah melihat saluran
              napas secara langsung dari bagian atas hingga ke bawah, melihat
              adanya kelainan-kelainan bawaan seperti laringomalasia,
              trakeomalasia, bronkomalasia atau kombinasi diantaranya, melihat
              adanya tumor atau pembesaran kelenjar di dalam rongga saluran
              napas, dan sebagainya. Biopsi tumor dapat dilakukan dengan
              prosedur ini. Kelainan-kelainan bawaan lahir lainnya pun dapat
              dilihat secara langsung seperti adanya celah atau kebocoran di
              saluran napas (fistel trakeo-esofagus). Tujuan terapi dengan
              prosedur ini misalnya untuk menghilangkan sumbatan di saluran
              napas akibat benda asing (pecahan buah kacang, pecahan mainan, dan
              sebagai) atau sumbatan saluran napas akibat lendir yang mengental
              dapat terlihat.
            </p>
            <br />

            <p style="font-weight: bold">
              Apakah efek samping tindakan bronkoskopi?
            </p>
            <p>
              Pada umumnya tindakan bronkoskopi adalah aman, karena dilakukan
              sesuai prosedur dan dengan hati-hati. Efek samping atau komplikasi
              yang mungkin terjadi adalah pembengkakan dinding saluran napas
              pasca tindakan, produksi lendir yang relatif bertambah, mengi,
              atau perdarahan di saluran napas. Namun efek samping itu amatlah
              jarang terjadi. Efek samping lainnya yang dapat terjadi biasanya
              tidak berhubungan dengan prosedur ini, yaitu alergi terhadap
              obat-obatan anestesi dan sebagainya. Dengan pengamatan dan
              pengenalan dini terhadap reaksi efek samping yang terjadi disertai
              dengan penanganan yang tepat dan cepat, maka biasanya efek samping
              tersebut dapat diatasi dengan baik.
            </p>
          </div>
        </div>
        <div class="col-md-6 text-center">
          <img :src="require('../../assets/layanan/bronko.jpg')" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  created() {
    document.title = "Bronkoscopy RSUD GAMBIRAN KOTA KEDIRI";
  },
};
</script>
